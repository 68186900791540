import iconOne from '../../assets/images/icon/1.png';
import iconTwo from '../../assets/images/icon/2.png';
import iconThree from '../../assets/images/icon/3.png';
import iconFour from '../../assets/images/icon/4.png';

function ServicesHomeThree() {
    return (
        <>
            <section className="appie-service-area appie-service-3-area" id="service">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Keungguan Signal</h3>
                                <p>Keunggulan Aplikasi Signature Digital Kab. Aceh Tamiang</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <img src={iconOne} alt="" />
                                </div>
                                <h4 className="appie-title">Aman</h4>
                                <p>Memastikan Identitas Tandatangan Aman Dari Pemalsuan.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <img src={iconTwo} alt="" />
                                </div>
                                <h4 className="appie-title">Terintegrasi</h4>
                                <p>
                                    Aplikasi Signal Terintegrasi dengan Sistem Bsre (Badan
                                    Sertifikat Elektronik).
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <img src={iconThree} alt="" />
                                </div>
                                <h4 className="appie-title">2FA</h4>
                                <p>Penandatanganan dilengkapi dengan OTP Whatsapp dan Passphrase</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-4
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="800ms"
                            >
                                <div className="icon">
                                    <img src={iconFour} alt="" />
                                </div>
                                <h4 className="appie-title">Satu Data</h4>
                                <p>Satu Dashboard untuk Semua OPD, Memudahkan Untuk Tandatangan</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesHomeThree;
