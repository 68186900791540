import { useState } from 'react';
import PopupVideo from '../PopupVideo';

function FaqHomeEight() {
    const [showQuestion, setQuestion] = useState(0);
    const [showVideo, setVideoValue] = useState(false);
    const openQuestion = (e, value) => {
        e.preventDefault();
        setQuestion(value);
    };
    const handleShowVideo = (e) => {
        e.preventDefault();
        setVideoValue(!showVideo);
    };
    return (
        <>
            {showVideo && (
                <PopupVideo
                    videoSrc="//www.youtube.com/embed/wUZ2wBYRkQk?autoplay=1"
                    handler={(e) => handleShowVideo(e)}
                />
            )}
            <div className="appie-faq-8-area pt-100 pb-100" id="counter">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="appie-section-title">
                                <h3 className="appie-title">
                                    Pertanyaan Seputar Tandatangan Digital
                                </h3>
                                <p>FAQ Signal</p>
                            </div>
                            <div
                                className="faq-accordion wow fadeInRight mt-30"
                                data-wow-duration="1500ms"
                            >
                                <div
                                    className="accrodion-grp wow fadeIn faq-accrodion"
                                    data-wow-duration="1500ms"
                                    data-grp-name="faq-accrodion"
                                >
                                    <div
                                        className={`accrodion ${
                                            showQuestion === 0 ? 'active' : ''
                                        }`}
                                        onClick={(e) => openQuestion(e, 1)}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Apa Itu Sertifikat Elektronik?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQuestion === 0 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Berdasarkan pasal 1 Undang-Undang Nomor 19
                                                        Tahun 2016 tentang Informasi dan Transaksi
                                                        Elektronik, Sertifikat Elektronik adalah
                                                        “sertifikat yang bersifat elektronik yang
                                                        memuat Tanda Tangan Elektronik dan identitas
                                                        yang menunjukkan status subjek hukum para
                                                        pihak dalam Transaksi Elektronik yang
                                                        dikeluarkan oleh Penyelenggara Sertifikasi
                                                        Elektronik”. Singkatnya, Sertifikat
                                                        Elektronik merupakan identitas yang dimiliki
                                                        seseorang dalam bentuk digital
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`accrodion ${
                                            showQuestion === 1 ? 'active' : ''
                                        }`}
                                        onClick={(e) => openQuestion(e, 1)}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>
                                                    Bagaimana Cara Mendapatkan Sertifikat
                                                    Elektronik?
                                                </h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQuestion === 1 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Untuk mendapatkan sertifikat Elektronik di
                                                        lingkungan pegawai pemerintahan kabupaten
                                                        Aceh Tamiang dapat mengajukan request ke
                                                        Dinas Komunikasi Informatika dan Persandian
                                                        Kabupaten Aceh Tamiang untuk dapat
                                                        diverifikasi dan diajukan ke Balai
                                                        Sertifikasi Elektronik (BSrE) Badan Siber
                                                        dan Sandi Negara (BSSN)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`accrodion ${
                                            showQuestion === 2 ? 'active' : ''
                                        }`}
                                        onClick={(e) => openQuestion(e, 2)}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Bagaimana Bentuk Sertifikat Elektronik?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQuestion === 2 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Sertifikat Elektronik merupakan identitas
                                                        yang dimiliki seseorang dalam bentuk digital
                                                        atau file softcopy bukan berbentuk fisik
                                                        seperti KTP atau paspor.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`accrodion ${
                                            showQuestion === 3 ? 'active' : ''
                                        }`}
                                        onClick={(e) => openQuestion(e, 2)}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Apa Keuntungan Menggunakan TTE?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQuestion === 2 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Penggunaan Sertifikat Elektronik mempunyai
                                                        banyak manfaat. Diantaranya, dapat
                                                        mempercepat proses bisnis, menghemat waktu,
                                                        dapat menjamin keaslian dari suatu dokemen,
                                                        dapat digunakan sebagai verifikasi identitas
                                                        di dunia siber, ramah lingkungan karena
                                                        dapat menghemat penggunaan kertas.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="faq-play-box">
                    <div className="play-btn">
                        <a
                            onClick={(e) => handleShowVideo(e)}
                            className="appie-video-popup"
                            href="https://www.youtube.com/watch?v=wUZ2wBYRkQk"
                        >
                            <i className="fas fa-play" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FaqHomeEight;
