function ServiceDownload({ className }) {
    return (
        <>
            <section className={`appie-services-2-area pb-100 ${className}`} id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6 col-md-8">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Download File</h3>
                                <p>File - File Terkait Tandatangan Elektronik.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-list" />
                                </div>
                                <h4 className="title">Formulir Pengajuan TTE</h4>
                                <p>File Formulir Pengajuan TTE</p>
                                <a href="#">
                                    <i className="fal fa-download" /> Download File
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-inbox" />
                                </div>
                                <h4 className="title">Surat Pengajuan Email Dinas</h4>
                                <p>File Surat Pengajuan Email Dinas</p>
                                <a href="#">
                                    <i className="fal fa-download" /> Download File
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-book" />
                                </div>
                                <h4 className="title">Manual Book Aplikasi Signal</h4>
                                <p>File Manual Book Aplikasi Signal</p>
                                <a href="#">
                                    <i className="fal fa-download" /> Download File
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServiceDownload;
