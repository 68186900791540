import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import HomeOneHeader from '../HomeOne/HomeOneHeader';
import ProjectHomeOne from '../HomeOne/ProjectHomeOne';
import DownloadHomeThree from '../HomeThree/DownloadHomeThree';
import Drawer from '../Mobile/Drawer';
import HeroDownload from './HeroDownload';
import ServiceDownload from './ServiceDownload';

function HomeOne() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader action={drawerAction.toggle} />
            <HeroDownload />
            <ServiceDownload />
            <DownloadHomeThree />
            <ProjectHomeOne />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default HomeOne;
