import logo from '../../assets/images/logo_signal.png';

function FooterHomeOne({ className }) {
    return (
        <>
            <section className={`appie-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <a href="#">
                                        <img width="60%" src={logo} alt="" />
                                    </a>
                                </div>
                                <p>
                                    Signature Digital (Signal) - Aplikasi Manajemen TTE Kab. Aceh
                                    Tamiang
                                </p>
                                <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/channel/UC5xeYQCNbSpnCGkCWw-IU3w">
                                                <i className="fab fa-youtube" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-8">
                            <div className="footer-widget-info">
                                <h4 className="title">Hubungi Kami</h4>
                                <ul>
                                    <li>
                                        <a href="mail:support-tte@acehtamiangkab.go.id">
                                            <i className="fal fa-envelope" />{' '}
                                            support-tte@acehtamiangkab.go.id
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://wa.me/6281263363698">
                                            <i className="fal fa-phone" /> +(62) 812 6336 3698
                                            (Aleka)
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://wa.me/6282366833180">
                                            <i className="fal fa-phone" /> +(62) 823-6683-3180
                                            (Revi)
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://wa.me/6281269494591">
                                            <i className="fal fa-phone" /> +(62) 812-6949-4591
                                            (Adjie)
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://wa.me/6285359657086">
                                            <i className="fal fa-phone" /> +(62) 853-5965-7086
                                            (Zulfat)
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-map-marker-alt" /> Dinas Komunikasi
                                            Informatika dan Persandian Kab.Aceh Tamiang
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-apple" /> Download for iOS
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="item-2"
                                                href="https://play.google.com/store/apps/details?id=com.kominfoatam.signalapps"
                                            >
                                                <i className="fab fa-google-play" /> Download for
                                                Android
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>
                                        Copyright © 2022 Signal - TTE Kab.Aceh Tamiang. All rights
                                        reserved.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;
