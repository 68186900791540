import downloadThumb from '../../assets/images/download-thumb-1.png';
import downloadThumbTwo from '../../assets/images/download-thumb-2.png';

function DownloadHomeThree({ className }) {
    return (
        <>
            <section className={`appie-download-3-area pt-100 ${className || ''}`} id="download">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Download Aplikasi Signal!</h3>
                                <p>Download Aplikasi Signal</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div
                                className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="content">
                                    <h4 className="title">Android</h4>
                                    <p>Download Signal Untuk Android - Gratis!</p>
                                    <a
                                        className="main-btn"
                                        href="https://play.google.com/store/apps/details?id=com.kominfoatam.signalapps"
                                    >
                                        <i className="fab fa-google-play" />
                                        Download for Android
                                    </a>
                                </div>
                                <div className="thumb text-center">
                                    <img src={downloadThumb} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="content">
                                    <h4 className="title">Windows & Mac Os</h4>
                                    <p>Download Aplikasi Signal Untuk Windows & Mac Os - Gratis.</p>
                                    <a className="main-btn main-btn-1 mr-2" href="#">
                                        <i className="fab fa-windows" />
                                        Download for Windows
                                    </a>

                                    <a className="main-btn main-btn-1" href="#">
                                        <i className="fab fa-apple" />
                                        Download for iOS
                                    </a>
                                </div>
                                <div className="thumb text-right">
                                    <img src={downloadThumbTwo} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DownloadHomeThree;
