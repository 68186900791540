import { useHistory } from 'react-router-dom';
import Svg from './Svg';

function Error() {
    const history = useHistory();
    const goBack = (e) => {
        e.preventDefault();
        history.goBack();
    };

    return (
        <>
            <div className="appie-error-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="appie-error-content text-center">
                                <Svg />
                                <span>Maaf!</span>
                                <h3 className="title">Halaman Yang Anda Cari Tidak Ditemukan.</h3>
                                <p>
                                    Halaman yang anda cari tidak ditemukan, silahkan kembali ke
                                    halaman utama
                                </p>
                                <a onClick={(e) => goBack(e)} href="/">
                                    Kembali ke Halaman Utama <i className="fal fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Error;
