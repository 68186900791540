import { useState } from 'react';
import logo from '../../assets/images/logo_signal.png';

function Drawer({ drawer, action }) {
    const [setSize] = useState('0px');
    const [setItem] = useState('home');
    const handler = (e, value) => {
        // e.preventDefault();
        const getItems = document.querySelectorAll(`#${value} li`).length;
        if (getItems > 0) {
            setSize(`${43 * getItems}px`);
            setItem(value);
        }
    };
    return (
        <>
            <>
                <div
                    onClick={(e) => action(e)}
                    className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
                ></div>
                <div className="offcanvas_menu">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className={`offcanvas_menu_wrapper ${drawer ? 'active' : ''}`}>
                                    <div className="canvas_close">
                                        <a href="#" onClick={(e) => action(e)}>
                                            <i className="fa fa-times"></i>
                                        </a>
                                    </div>
                                    <div className="offcanvas-brand text-center mb-40">
                                        <img src={logo} alt="" />
                                    </div>
                                    <div id="menu" className="text-left ">
                                        <ul className="offcanvas_main_menu">
                                            <li
                                                onClick={(e) => handler(e, 'home')}
                                                id="home"
                                                className="menu-item-has-children active"
                                            >
                                                <a href="/">Beranda</a>
                                            </li>
                                            <li
                                                onClick={(e) => handler(e, 'service')}
                                                id="service"
                                                className="menu-item-has-children active"
                                            >
                                                <a
                                                    href="https://www.youtube.com/watch?v=wUZ2wBYRkQk&t=3s"
                                                    target="blank"
                                                >
                                                    Tutorial
                                                </a>
                                            </li>
                                            <li
                                                onClick={(e) => handler(e, 'pages')}
                                                id="pages"
                                                className="menu-item-has-children active"
                                            >
                                                <a href="/faq">FAQ</a>
                                            </li>
                                            <li
                                                onClick={(e) => handler(e, 'news')}
                                                id="news"
                                                className="menu-item-has-children active"
                                            >
                                                <a href="https://wa.me/6281269494591">
                                                    Hubungi Kami
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
}

export default Drawer;
