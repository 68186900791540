import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import HeroHomeSix from '../HomeSix/HeroHomeSix';
import DownloadHomeThree from '../HomeThree/DownloadHomeThree';
import ServicesHomeThree from '../HomeThree/ServicesHomeThree';
import CounterArea from '../HomeTwo/CounterArea';
import VideoPlayerHomeTwo from '../HomeTwo/VideoPlayerHomeTwo';
import Drawer from '../Mobile/Drawer';
import FaqHomeOne from './FaqHomeOne';
import FooterHomeOne from './FooterHomeOne';
import HomeOneHeader from './HomeOneHeader';
import ProjectHomeOne from './ProjectHomeOne';
import TrafficHomeOne from './TrafficHomeOne';

function HomeOne() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader action={drawerAction.toggle} />
            <HeroHomeSix />
            <CounterArea />
            <ServicesHomeThree />
            <TrafficHomeOne />
            <VideoPlayerHomeTwo />
            <FaqHomeOne />
            <DownloadHomeThree />
            <ProjectHomeOne />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default HomeOne;
