import { Link } from 'react-router-dom';

function Navigation({ lang = false }) {
    return (
        <>
            {lang ? (
                <ul>
                    <li>
                        <a href="/">Beranda</a>
                    </li>
                    <li>
                        <Link to="/download-file">Download File</Link>
                    </li>
                    <li>
                        <Link to="/faq">FAQ</Link>
                    </li>
                </ul>
            ) : (
                <ul>
                    <li>
                        <a href="/">Beranda</a>
                    </li>
                    <li>
                        <a
                            href="https://www.youtube.com/watch?v=wUZ2wBYRkQk&t=3s"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Tutorial
                        </a>
                    </li>
                    <li>
                        <Link to="/download-file">Download File</Link>
                    </li>
                    <li>
                        <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                        <a
                            href="https://wa.me/6281269494591"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Hubungi Kami
                        </a>
                    </li>
                </ul>
            )}
        </>
    );
}

export default Navigation;
